import axios from "axios";

const configOrp = {
    // baseURL: ""
    baseURL:""
    // http://172.23.36.206:31094/
  }
  
  const apiOrp = axios.create(configOrp);

  apiOrp.defaults.headers["content-type"] = "application/json";
  apiOrp.defaults.headers["unmaskrequest"] = "enable";
  apiOrp.defaults.headers["product"] = "ORP";
  apiOrp.defaults.headers["x-app-version"] = "1.0.0";
  apiOrp.defaults.headers["x-api-key"] = (process.env.REACT_APP_MODE === "UAT" ?
  process.env.REACT_APP_BFE_API_KEY_UAT : process.env.REACT_APP_BFE_API_KEY_PROD);
  apiOrp.defaults.headers["x-service-id"] =  (process.env.REACT_APP_MODE === "UAT" ?
  process.env.REACT_APP_BFE_SERVICE_ID_UAT :process.env.REACT_APP_BFE_SERVICE_ID_PROD);
  apiOrp.defaults.headers["Opco"] = process.env.REACT_APP_OPCO;
  apiOrp.defaults.headers["run-mode"] = 'beta';
  
  apiOrp.interceptors.request.use(item => {
    return item;
  });
  
  apiOrp.interceptors.response.use(
    resp => {
      return resp;
    },
    err => {
      console.log(err)
    }
  );
export default apiOrp;
  
