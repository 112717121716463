 import React, { useEffect, useState } from "react"
 import { useNavigate } from "react-router-dom";
 import { intiatePayment,processTrans } from "../../../shared/service";
 import './CardPayment.scss'
 import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
 import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
 import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";

 const CardPayment=()=>{
    const [formData, setFormData]=useState({email:''})
    const [pgOptions, setPaymentOption]=useState();
    const [selectedPaymentMethod, setSelectedPaymentMethod]=useState()
    const [email, setEmailId]=useState();
    const  [txnInfo, setTxnInfo]=useState();
    const [emailError, setEmailError] = useState("");
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useLoading();
    const [toastMsg ,setToastMsg]=useState(null);
    
    useEffect(()=>{
         setTxnInfo(JSON.parse(sessionStorage.getItem('queryData')));
         setPaymentOption(txnInfo?.pgOptions)
         setEmailId(txnInfo?.emailId)
    },[])

    useEffect(()=>{
        let transactionInfo=JSON.parse(sessionStorage.getItem('queryData'))
         setPaymentOption(transactionInfo?.pgOptions)
         setSelectedPaymentMethod(transactionInfo?.pgOptions[0]?.paymentOpt)
  },[])

  const showToastMsg= (type,message)=>{
    setToastMsg({type,message});
    setTimeout(()=>setToastMsg(null),10000);
  }
    
    const handleChange=(e)=>{
      const{name,value}=e.target;
      setFormData({...formData,[name]:e.target.value})
      if(name=='email'){
        if (value.trim() === "") {
          setEmailError("Email is required");
          return;
        } 
        if(!e.target.checkValidity()){
          setEmailError("Invalid Email Id");
        }
        else{
          setEmailError(""); 
        }
      }
    }

    const getPgImageUrl=(paymentOpt)=> {
        let imageUrl;
        switch (paymentOpt.toLowerCase()) {
          case 'paystack':
            imageUrl = 'assets/icons/paystack.svg';
            break;
    
          case 'uba':
            imageUrl = 'assets/icons/UBA.svg';
            break;
    
          case 'dpo':
            imageUrl = 'assets/icons/DPO.svg';
            break;
            
          case 'cellulant':
            imageUrl = 'assets/icons/tingg.svg';
            break;
    
          default:
            imageUrl = 'assets/icons/other_card.svg';
            break;
        };
        return imageUrl;
      }
      
 const cancelTransaction=()=>{
    navigate(txnInfo.redirectPage);
 }
 
 const initiateRecharge=()=>{
    showLoader();
    let postRequestData = {
        "amount": txnInfo?.amount, //Esb api object
        'countryCode': process.env.REACT_APP_COUNTRY_CODE || "234",
        "emailId":formData.email || txnInfo?.email,
        "mobileNumber":txnInfo?.msisdn,
        "currencyCode": process.env.REACT_APP_CURRENCY,
        "productCode":txnInfo?.productCode || null,
        "paymentGateway":selectedPaymentMethod,
        "transactionType":txnInfo?.transactionType,
        "unit":txnInfo?.amount,
        "loggedInNumber":txnInfo?.invoiceDetail &&txnInfo?.invoiceDetail?.accountNo || null,
        "accountId":txnInfo?.invoiceDetail &&txnInfo?.invoiceDetail?.accountNo || null,
        "invoiceNumber":txnInfo?.invoiceDetail &&txnInfo?.invoiceDetail?.invoiceNo || null,
        "accountType":txnInfo?.invoiceDetail &&txnInfo?.invoiceDetail?.accountNo ? "ENTERPRISE" : null,
        "paymentMode": process.env.REACT_APP_CREDIT_DEBIT_PAYMENT_MODE,
       };
       let postRequest = {
         data: postRequestData
       };
       intiatePayment(postRequest).then(
        (res) => {
          hideLoader()
          res = res?.data;
          if (
            res && (res?.statusCode == 200 || res?.status.toLowerCase() == "success")
          ) {
            res.result.transactionId ? processRecharge(res?.result?.transactionId) : console.error("error txn id not found",res)
            eventEmitter('ORP','Initiate Card Payment Success','click','Initiate Card Payment Success',null);
          }
          else {
            hideLoader()
            showToastMsg('Error',res?.message || "Something Went Wrong!")
           console.log('error');
           eventEmitter('ORP','Initiate Card Payment Failed','click',res?.message,null);
           }
       })
     .catch((error)=>{
      hideLoader()
       console.log(error);
       showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
       eventEmitter('ORP','Initiate Card Payment Failed','click',error?.error?.toString(),null);
     })
 }

 const processRecharge=(txnId)=>{
  showLoader();
    let postRequestData = {
        transactionId: txnId,
        isSubscriberRequest: true,
        paymentGateway: selectedPaymentMethod,
        paymentMode: process.env.REACT_APP_CREDIT_DEBIT_PAYMENT_MODE,
        proccessFlag: true,
        accountId:txnInfo.invoiceDetail && txnInfo.invoiceDetail.accountNo || null,
        accountType: txnInfo.invoiceDetail && txnInfo.invoiceDetail.accountNo ? "ENTERPRISE" : null
    };
    
    let postRequest = {
      data: postRequestData
    };
    processTrans(postRequest).then(
      (res) => {
        res = res?.data;
        hideLoader()
        if (
          res && (res.statusCode == 200 || res.status.toLowerCase() == "success")
        ) {
          eventEmitter('ORP','Process Card Payment Success','click','Process Card Payment Success',null);
          if(selectedPaymentMethod == 'PAYSTACK'){
            payStack(txnId);
            eventEmitter('ORP','PAYSTACK Selected','click','PAYSTACK Selected',null);
           }
           else if(res?.result?.redirectUrl){
            window.open(res?.result?.redirectUrl,'_self');
           }
        }
        else {
        showToastMsg('Error',res?.message || "Something Went Wrong!")
        console.log('error');
        eventEmitter('ORP','Process Card Payment Failed','click',res?.statusCode?.toString(),null);
        }
    })
    .catch((error)=>{
      hideLoader()
      console.log(error);
      showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
      eventEmitter('ORP','Process Card Payment Failed','click',error?.error?.toString(),null);
    })
 }
 const payStack=(txnId)=>{
  var handler = window['PaystackPop'].setup({
    key: sessionStorage.getItem('_p_y_m_e_n_t_k_e_'),
    email: email,
    amount: parseInt(txnInfo.amount)*100,
    ref: txnId,
    channels:process.env.REACT_APP_PAY_STACK_CHANNEL,
    currency: 'NGN',
    metadata:{
      custom_fields: [
        {
            display_name: "Mobile Number",
            variable_name: "msisdn",
            value: txnInfo.msisdn
        },
        {
          display_name: "Payment Type",
          variable_name: "transactionType",
          value: txnInfo.transactionType
      },
    ]
    },
    onClose: function(response){
      window.location.href = '/api/portal-service/transaction/v1/pg-callback/status/ORP/paystack?txref='+txnId+'&status=CANCELLED';
      eventEmitter('ORP','PAYSTACK Cancel transaction','click','PAYSTACK cancel transaction',null);

    },
    callback: function(response){
      window.location.href = '/api/portal-service/transaction/v1/pg-callback/status/ORP/paystack?txref='+txnId+'&PnrID='+response.transaction+'&status='+response?.status;
      eventEmitter('ORP','PAYSTACK Success transaction','click','PAYSTACK Success transaction',null);
    }
  });

  
  handler.openIframe();

}

 return (<>
    <div className="card-pay-container">
      <h3>How will you pay</h3>
      <h6>Payment Method</h6>

     <div className="card-pay-items-wrap">
     { pgOptions && pgOptions.map((payment,index)=>(
        <div className="card-pay-items" >
            <span className={'pg-image '+ (selectedPaymentMethod==payment.paymentOpt ? 'active' :'')} onClick={()=> setSelectedPaymentMethod(payment.paymentOpt)}>
                <img src={getPgImageUrl(payment.paymentOpt)}/>
                {/* <div className="radio">
                 <input type="radio" checked name="pgOptions" id="pgOptions" className="pgOptionsInput" />
                <label className="pgOptionsLable" for="pgOptions"></label>
                </div> */}
            </span>
        </div>
     ))}
     </div>
     <div className="card-pay-footer">
      <div className="card-pay-form">
        <img src="assets/icons/email.svg" />
        <input autoComplete="off"
          type="text"
          name="email"
          placeholder="Email id"
          value={formData.email}
          required
          pattern="^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,}$"
          className="form-control"
          onChange={handleChange}
        />
         {emailError && <p className="error" >{emailError}</p>}
      </div>
      <div className="card-pay-payment-button">
       <button onClick={cancelTransaction} type="" className="cancel-btn">Cancel</button>
        <button className="continue-btn" type="" disabled={emailError || !formData.email} onClick={initiateRecharge}>
        Continue to pay
      </button>
     </div>  </div>
    </div>
    {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}
   </>)
 }
 export default CardPayment