import apiOrp from "../../../../Shared/interceptor/apiOrp";
import {
  LINE_TYPE_URL,
  FETCH_CONFIGURATION,
  GET_OTP_URL,
  RESEND_OTP_URL,
  VERIFY_OTP_URL,
  FETCH_BILL_URL,
  VALIDATE_MSIDN_ACCOUNT_URL,
  GET_INVOICE_DETAIL,
  GET_BUNDLE_URL,
  PAYMENT_OPTIONS,
  INTIATE_PAYMENT,
  PROCESS_TRXN,
  GET_OTP_V1,
  VERIFY_OTP_V1,
  GET_QUERY_BALANCE,
  CHECK_AM_STATUS,
  RESEND_OTP_V1,
  FETCH_PAYMENT_STATUS
} from "../../helper/constants";


export function getLineType(requestdata) {
    return apiOrp.post(LINE_TYPE_URL,requestdata)
}
export function getBundles(req){
    return apiOrp.post(GET_BUNDLE_URL,req.data)
}
export function fetchConfiguration() {
  return apiOrp.get(FETCH_CONFIGURATION)
}

export function getOtp(req){
  return apiOrp.post(GET_OTP_URL,req.data)
}

export function getInvoice(req){
  return apiOrp.post(GET_INVOICE_DETAIL,req)
}

export function resendOtp(req) {
  return apiOrp.post(RESEND_OTP_URL, req.data);
}

export function verifyOtp(req) {
  return apiOrp.post(VERIFY_OTP_URL, req.data);
}

export function validateMsidnViaAccount(req) {
  return apiOrp.post(VALIDATE_MSIDN_ACCOUNT_URL, req.data)
}

export function getBillAmount(req){
  return apiOrp.post(FETCH_BILL_URL,req.data)
}

export function getPaymentOptions(req){
  return apiOrp.post(PAYMENT_OPTIONS,req.data)
}

export function intiatePayment(req){
  return apiOrp.post(INTIATE_PAYMENT,req.data)
}

export function processTrans(req){
  return apiOrp.post(PROCESS_TRXN,req.data)
}

export function getOtpV1(req){
   return apiOrp.post(GET_OTP_V1,req.data)
}

export function verifyOtpV1(req){
  return apiOrp.post(VERIFY_OTP_V1,req.data)
}

export function getQueryBalance(req){
  return apiOrp.post(GET_QUERY_BALANCE,req.data)
}

export function checkAMstatus(txnId) {
  return apiOrp.get(CHECK_AM_STATUS+txnId)
}

export function resendOtpV1(req){
  return apiOrp.post(RESEND_OTP_V1,req.data)
}

export function getPaymentStatus(txnId) {
  return apiOrp.get(FETCH_PAYMENT_STATUS+txnId)
}



