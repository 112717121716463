import React, { useState, useRef, useEffect } from "react";
import { verifyOtpV1 ,getQueryBalance,resendOtpV1} from "../../../shared/service";
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";

const AtOtpModal = ({ txnInfo, toggleModalEvent, refId, sendShowProceedEvent,insufficientBalanceEvent ,sendInitiateEvent,sendUniqueCodesEvent,formInput}) => {
    const [formData, setFormData] = useState(new Array(6).fill(""));
    const inputRefs = useRef([]);
    const [otp, setOtp] = useState();
    const [timer, setTimer] = useState(60);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [uniqueCodes, setUniqueCodes] = useState([]);
    const [amountDeductionAlert, setAmountDeductionAlert] = useState(false);
    const [isValidOtp,setIsValidOtp]=useState(false);
    const [atBalance,setAtBalance]=useState();
    const [showOtpModal,setShowOtpModal]=useState(true);
    const { showLoader, hideLoader } = useLoading();
    const [toastMsg ,setToastMsg]=useState(null);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);

            return () => clearInterval(interval); // Cleanup interval on unmount
        } else {
            setIsResendDisabled(false); // Enable the resend button when timer reaches 0
        }
    }, [timer]);

    const showToastMsg= (type,message)=>{
        setToastMsg({type,message});
        setTimeout(()=>setToastMsg(null),10000);
      }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60).toString().padStart(2, '0');
        const seconds = (time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
      };


    const handleResendOtp = () => {
        showLoader();
        setTimer(10); // Reset timer back to 60 seconds
        setIsResendDisabled(true); // Disable resend button until timer is done
        // // this.loaderService.startLoading();
        let postRequestData = {
            refId: refId
        };
        let postRequest = {
            data: postRequestData,
        };
        resendOtpV1(postRequest).then(
            (res) => {
                hideLoader()
                // this.loaderService.stopLoading();
                let data;
                res = res?.data;
                if (
                    res &&
                    (res?.status == 200 || res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
                ) {
                    refId= res?.result?.refId;
                    eventEmitter('ORP', 'Airtime Payment Resend Otp Success', 'click', 'Airtime Resend Otp Success', null);
                    showToastMsg('Success',res?.message || res?.result.message )
                    return;
                } else {
                    showToastMsg('Error',res?.message || "Something Went Wrong!")
                    eventEmitter('ORP', 'Airtime Payment Resend Otp Failed', 'click', res?.message, null);

                }
            },
            (error) => {
                hideLoader();
                showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
                eventEmitter('ORP', 'Postpaid Bill Payment Resend Otp Failed', 'click', error?.error?.statusCode?.toString(), null);  

            }
        );
    };


    const handleChange = (element, index) => {
        const value = element.value;
        if (/^\d$/.test(value) || value === "") { // Allow only single digit numbers
            const newOtp = [...formData];
            newOtp[index] = value;
            setFormData(newOtp);

            // Move to the next input box
            if (value !== "" && index < 5) {
                inputRefs.current[index + 1].focus();
            }
            if (index === 5 ) {
                generateEnteredOtp([...formData,value]);
                verifyOtp([...formData,value]);
            }
        }
    };



    const generateEnteredOtp = (otp) => {
        setOtp(Object.values(otp).join(""));
    }

    const verifyOtp = (otp) => {
        // this.loaderService.startLoading();
        let postRequestData = {
            refId: refId,
            otp: Object.values(otp).join("") 
        }
        let postRequest = {
            data: postRequestData,
        };
        showLoader();
        verifyOtpV1(postRequest).then(
            (res) => {
                // this.loaderService.stopLoading();
                let data;
                hideLoader()
                res = res?.data;
                if (
                    res &&
                    (res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
                ) {
                    console.log("res", res);
                    //   this.uniqueCode=res.result?.uniqueCode
                    setUniqueCodes(res.result?.uniqueCode);
                    sendUniqueCodesEvent(res.result?.uniqueCode);
                    // toggleModalEvent();
                    setShowOtpModal(false);
                    fetchQueryBalance(res.result?.uniqueCode);
                    // this.error = null;
                    setIsValidOtp(true);
                } else {
                  showToastMsg('Error',res?.message || "Something Went Wrong!")
                    // setFormData([]);
                    // this.rows._results[0].nativeElement.focus();
                    // let toastData = {
                    //     msg: res.message || "something went wrong!",
                    // };
                    // this._toaster.Error(
                    //     'Error', toastData.msg);
                }
            },
            (error) => {
                hideLoader();
                showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")

                // this.loaderService.stopLoading();
                // setFormData([]);
            //     let toastData = {
            //         msg:
            //             error.error.message ||
            //             error.error.resp.message ||
            //             "something went wrong!",
            //     };
            //     this._toaster.Error(
            //         'Error', toastData.msg);
            }
        );
    }

    const fetchQueryBalance = (uniqueCode) => {
        // this.balance = 0;
        // this.loaderService.startLoading();
        let postRequestData = {
            serviceType: "Airtime",
            msisdn: process.env.REACT_APP_OPCO?.toLowerCase() == "ga" ? "0" + formInput?.mobileNo : formInput?.mobileNo,
            uniqueCode: uniqueCode && uniqueCode || null
        }
        let postRequest = {
            data: postRequestData,
        };
        showLoader();
        getQueryBalance(postRequest).then(
            (res) => {
                // this.loaderService.stopLoading();
                let data;
                res = res?.data;
                hideLoader();
                if (
                    res &&
                    (res.statusCode == 200 || res.status.toLowerCase() == "success")
                ) {
                    console.log("res", res);
                    let balance;
                    // if(res.result && res.result.active){
                    res?.result?.queryBalanceResult && res?.result?.queryBalanceResult.map(element => {
                        if (element?.serviceType == 'AIRTIME') {
                            balance = element.balance;
                            setAtBalance(element.balance)
                        }
                    })
                    if (balance && balance >= txnInfo.amount) {
                        setAmountDeductionAlert(true);
                    } else {
                       insufficientBalanceEvent(true)
                    }
                    // }
                } else {
                    showToastMsg('Error',res?.message || "Something Went Wrong!")
                    sendShowProceedEvent(false);
                    // toggleModalEvent();
                }
            }
            
        ).catch(error=>{
            hideLoader();
            // this.loaderService.stopLoading();
            // let toastData = {
            //   msg:
            //     error?.error?.message ||
            //     error.error?.resp?.message ||
            //     "something went wrong!",
            // };
            //     this._toaster.Error(
            //         'Error',toastData.msg);
            //   }
            // );
                
        })
    }
    


    const handleBackspace = (e, index) => {
            if (e.key === "Backspace" && index > 0 && formData[index] === "") {
                inputRefs.current[index - 1].focus();
            }
        };



        return (<>
            {showOtpModal && <div id="myModal" className=" otp_verification-outer adv-modal modal fade in" role="dialog">
                <div className="otp-modal otp_verification_modal">
                    {/* <!-- Modal content--> */}
                    <div className="otp-content">
                        <div className="otp-header">
                            {/* <button type="button" className="close" data-dismiss="modal" onClick={''}>×</button>
                             */}
                            <img className="verification-close" src="assets/icons/left-arr.svg" alt="x" onClick={toggleModalEvent} />
                            <h3>OTP verification</h3>
                            <p>Please enter OTP you received on {txnInfo.msisdna}</p>
                            {/* <!--         <h4 className="modal-title">Modal Header</h4> --> */}
                        </div>
                        <div className="modal-body">
                            {/* <div>{`Enter your ${process.env.REACT_APP_ACCOUNT_NUMBER_LENGTH} digit account number`} </div> */}
                            <div className="otp_bx_wrap">
                                {formData?.map((data, index) => (
                                    <input

                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={formData[index]}
                                        onChange={(e) => handleChange(e.target, index)}
                                        onKeyDown={(e) => handleBackspace(e, index)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                       
                                    />
                                ))}
                            </div>
                            {/* {accountErrorMsg} */}
                        </div>
                        {/* add css disable calss using method isAccountLengthInValid */}
                        <div className="otp_bx_bottom">
                        <span onClick={handleResendOtp} disabled={isResendDisabled} >RESEND</span>

<span>{formatTime(timer)}</span>
                        </div>
                       
                    </div>
                </div>
            </div>}
            {amountDeductionAlert && <div id="myModal" className="otp_verification-outer pop-up-outer adv-modal modal fade in" role="dialog">
                <div className="otp-modal otp_balance_modal">
                    {/* <!-- Modal content--> */}
                    <div className="otp-content">
                        <div className="otp-header">
                            <img className="balance-close" src="assets/icons/close.svg" onClick={()=>setAmountDeductionAlert(false)}/>
                            <p><strong>Are you sure?</strong></p>
                        </div>
                        <div className="modal-body ">
                            <p>Your balance will be deducted by <span>{txnInfo?.currency}{txnInfo.amount} </span>from <span>{txnInfo.currency}{atBalance}</span></p>
                        </div>
                                    <div className="modal_balance_footer">
                                    <span onClick={()=>setAmountDeductionAlert(false)} >No</span>
                        <span onClick={sendInitiateEvent}>Yes</span>
                                    </div>
                    </div>
                </div>
            </div>}
            {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}
        </>)
    }

    export default AtOtpModal;