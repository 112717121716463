import React, { useState, useEffect } from "react";
import FormContainer from "../../../shared/components/FormContainer/FormContainer";
import BundleTable from "../../../shared/components/BundleTable";
import { fetchConfiguration } from "../../../shared/service";
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { useNavigate } from "react-router-dom";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";

const BroadbandRecharge = () => {
  const [formData, setFormData] = useState({ msisdn: "" });
  const [lineTypeRes, setLineType] = useState(false);
  const [msisdnLineType, setmsisdnLineType] = useState("");
  const { showLoader, hideLoader } = useLoading();
  const [toastMsg ,setToastMsg]=useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let widgetInfo = sessionStorage.getItem("widgetInfo");
    widgetInfo = JSON.parse(widgetInfo);
    if((widgetInfo?.type)?.toLowerCase()=="broadband"){
      setFormData({...formData,msisdn:widgetInfo?.msisdn})
    }
    fetchConfig();
    eventEmitter('ORP','Broadband Recharge Page Load','page load','Broadband Recharge Page Load',null);
  }, []);

  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({ ...formData, [name]: e.target.value });
  };

  const crossClick = () => {
    setFormData({ ...formData, msisdn: "" });
    setLineType(false);
  };
  const handleDataForm = (data) => {
    setLineType(data);
    setmsisdnLineType("");
  };

  const fetchConfig = () => {
    showLoader();
    fetchConfiguration()
      .then((res) => {
        res = res?.data;
        hideLoader();
        if (
          res &&
          (res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
        ) {
          if (
            res?.result?.configData?.payStackConfig &&
            JSON.parse(res?.result?.configData?.payStackConfig).key
          ) {
            sessionStorage.setItem(
              "_p_y_m_e_n_t_k_e_",
              JSON.parse(res?.result?.configData?.payStackConfig).key,
              true
            );
          }
          if (res?.result?.configData) {
            sessionStorage.setItem(
              "_c_o_n_f_i_g",
              JSON.stringify(res?.result?.configData),
              true
            );
          }
          eventEmitter('ORP','Broadband Recharge Fetch Config Success','click','Broadband Recharge Fetch Config Success',null);
        }
        else{
          showToastMsg('Error',res?.message || "Something Went Wrong!")
          eventEmitter('ORP','Broadband_recharge_fetch_config_failed','click',res?.message,null);
      }
      })
      .catch((error) => {
        hideLoader();
        console.log(error);
        showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
        eventEmitter('ORP','Broadband Recharge Fetch Config Failed','click',error?.error?.statusCode?.toString(),null);
      });
  };
  const showToastMsg= (type,message)=>{
    setToastMsg({type,message});
    setTimeout(()=>setToastMsg(null),10000);
  }

  const handleBundleEvent=(bundleInfo)=>{
    if (!bundleInfo) {
      return;
    }
    onPackSelect(bundleInfo)
    
  }

  const onPackSelect = (event, bundleInfo) => {
    // event.persist();
    if (!bundleInfo) {
      return;
    }

    setFormData({ ...formData, amount: bundleInfo?.amount?.toString() });
    let txnData = {
      msisdn:process.env.REACT_APP_OPCO?.toLowerCase() == "ga" ? "0" + formData?.msisdn: formData?.msisdn,
      enableDualCurrency:process.env.REACT_APP_OPCO?.toLowerCase() == "cd" ? true : false,
      amount: bundleInfo?.amount,
      bundleInfo: bundleInfo,
      lineType: bundleInfo?.linetype || "PREPAID",
      productCode: bundleInfo?.productCode,
      description: "Broadband Bundle Purchase",
      currency: bundleInfo?.currency || process.env.REACT_APP_CURRENCY,
      redirectPage: "/broadband-recharge",
      rechargeType:  "Broadband Recharge",
      transactionType:
        (bundleInfo?.lineType || msisdnLineType).toLowerCase() == "prepaid"
          ? "PREPAID_BUNDLES"
          : "POSTPAID_BUNDLES",
    };
    sessionStorage.setItem("queryData", JSON.stringify(txnData), true);

    navigate("/payment/start");
  };

  return (
    <>
      <div class="online-recharge">
        <div class="container">
          <h2>Recharge or Buy Bundles</h2>
          <h4>Buy a Bundle!</h4>
        </div>
      </div>

      <div className="bundle-recharge-form">
        <div className="container">
          <div className="form-container">
            <FormContainer
              formInput={formData}
              handleChange={handleChange}
              sendDataToParent={handleDataForm}
              crossClick={crossClick}
              showAmountField={false}
              isBroadband={true}
            ></FormContainer>
          </div>
        </div>
      </div>

      {lineTypeRes && (
        <BundleTable linetype={msisdnLineType} msisdn={formData?.msisdn}  buyBundleEvent={onPackSelect} />
      )}
      {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}
    </>
  );
};
export default BroadbandRecharge;
