export const LINE_TYPE_URL=`/orp/bfe/api/portal-service/subscriber/line-type`;
export const FETCH_CONFIGURATION=`/orp/bfe/api/portal-service/resource/v1/get-product-config`;
export const GET_OTP_URL="/orp/bfe/api/portal-service/optimus/otp/send";
export const GET_INVOICE_DETAIL="/orp/bfe/api/portal-service/subscriber/getopeninvoice";
export const RESEND_OTP_URL = "/orp/bfe/api/portal-service/optimus/otp/resend";
export const VERIFY_OTP_URL = "/orp/bfe/api/portal-service/optimus/otp/verify";
export const VALIDATE_MSIDN_ACCOUNT_URL = "/orp/bfe/api/portal-service/subscriber/validate/msisdn-account"
export const FETCH_BILL_URL = "/orp/bfe/api/portal-service/subscriber/v1/bill";
export const GET_BUNDLE_URL = "/orp/bfe/api/portal-service/product/v1/bundle";
export const PAYMENT_OPTIONS= "/orp/bfe/api/portal-service/transaction/v1/payment-options";
export const INTIATE_PAYMENT='/orp/bfe/api/portal-service/transaction/v1/initiate';
export const PROCESS_TRXN='/orp/bfe/api/portal-service/transaction/v1/process';
export const GET_OTP_V1= "/orp/bfe/api/portal-service/otp/v1/send-otp";
export const VERIFY_OTP_V1='/orp/bfe/api/portal-service/otp/v1/verify-otp';
export const GET_QUERY_BALANCE="/orp/bfe/api/portal-service/subscriber/v4/query-balance";
export const CHECK_AM_STATUS="/orp/bfe/api/portal-service/transaction/v1/status?tid=";
export const RESEND_OTP_V1= "/orp/bfe/api/portal-service/otp/v1/resend-otp";
export const FETCH_PAYMENT_STATUS="/orp/bfe/api/portal-service/transaction/fetch?txnId=";

