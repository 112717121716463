import axios from "axios";

const config = {
  baseURL: process.env.REACT_APP_BASEURL
  // baseURL: "http://172.27.82.150:31094/"

}

const api = axios.create(config);

api.defaults.headers["content-type"] = "application/json";

api.interceptors.request.use(item => {
  return item;
});

api.interceptors.response.use(
  resp => {
    return resp;
  },
  err => {
    console.log(err)
  }
);

export default api;
