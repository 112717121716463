import React from "react";
import './ToastMsg.scss'


const ToastMsg =({type,message,onClose})=>{

    return(

          <>
            
                       {message && <div className={`toast_wrap 
                       ${type.toLowerCase()==='Error'.toLowerCase()?'toast-error':
                       type.toLowerCase()==='success'.toLowerCase()?'toast-success':''
                       }`}>
                            <h4>{type} </h4>
                            <span className="toastClose" onClick={onClose}>×</span>
                            <p className="toast-message">{message} </p>
                      </div>}
           
        </>
    )
}
export default ToastMsg