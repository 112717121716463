
export const eventEmitter=(eventCategory,eventName,eventAction,eventLabel,eventValue
  )=> {
    window.gtag('event', eventName, {
      'event_category': eventCategory,
      'event_action': eventAction,
      'event_label': eventLabel,
      'event_value': eventValue
    });
  }
  

