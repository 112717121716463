import React, { useState, useEffect } from "react";
import FormContainer from "../../../shared/components/FormContainer/FormContainer";
import { RECHARGE_TYPE } from "../../../enums/recharge-type.enum";
import BundleTable from "../../../shared/components/BundleTable";
import { fetchConfiguration } from "../../../shared/service";
import { useNavigate } from "react-router-dom";
import "./Prepaid.scss";
import { useLoading } from "../../../../../Shared/cache/context/LoadingProvider";
import ToastMsg from "../../../shared/components/ToastMsg/ToastMsg";
import { eventEmitter } from "../../../../../Shared/service/google-analytics.service";
import { sendGA4Event } from "../../../../../Shared/helper/google-analytics";

const Prepaid = () => {
  const [formData, setFormData] = useState({ msisdn: "", amount: "" });
  const [lineTypeRes, setLineType] = useState(false);
  const [msisdnLineType, setmsisdnLineType] = useState("");
  const [maxAmount, setmaxAmount] = useState("");
  const [minAmount, setminAmount] = useState("");
  const [inputText, setinputText] = useState("");
  const [minError, setMinError] = useState("");
  const [maxError, setMaxError] = useState("");
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoading();
  const [toastMsg ,setToastMsg]=useState(null);
  const [lineTypeData,setLineTypeData]=useState();
  const [msidnError,setMsidnError]=useState('')

  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({ ...formData, [name]: e.target.value });
    if (formData.amount && formData.amount < Number(minAmount)) {
      setMinError("Minimum amount should be");
    } else if (formData.amount && formData.amount > Number(maxAmount)) {
      setMaxError("Maximum amount should be");
    } else {
      setMinError("");
      setMaxError("");
      // setLineTypeData("");
    }
  };

  useEffect(() => {
    let widgetInfo = sessionStorage.getItem("widgetInfo");
    widgetInfo = JSON.parse(widgetInfo);
    if((widgetInfo?.type)?.toLowerCase()=="prepaid"){
      setFormData({...formData,msisdn:widgetInfo?.msisdn})
    }
    fetchConfig();
    eventEmitter('ORP','Prepaid Recharge Page Load','page load','Prepaid Recharge Page Load',null);
  }, []);

  const showToastMsg= (type,message)=>{
    setToastMsg({type,message});
    setTimeout(()=>setToastMsg(null),3000);
  }

  const fetchConfig = () => {
    showLoader();
    fetchConfiguration()
      .then((res) => {
        hideLoader();
        res = res?.data;
        if (
          res &&
          (res?.statusCode == 200 || res?.status?.toLowerCase() == "success")
        ) {
          setmaxAmount(res?.result?.configData?.preMaxAmnt);
          setminAmount(res?.result?.configData?.preMinAmnt);
          if (
            res?.result?.configData?.payStackConfig &&
            JSON.parse(res.result?.configData?.payStackConfig).key
          ) {
            sessionStorage.setItem(
              "_p_y_m_e_n_t_k_e_",
              JSON.parse(res.result?.configData?.payStackConfig).key,
              true
            );
          }
          if (res.result.configData) {
            sessionStorage.setItem(
              "_c_o_n_f_i_g",
              JSON.stringify(res?.result?.configData),
              true
            );
          }
          // setinputText(res?.result && res.result?.configData['landingPageMessage']['prepaid'] || '')
          // console.log('input',inputText);
          eventEmitter('ORP','Prepaid Recharge Fetch Config Success','click','Prepaid Recharge Fetch Config Success',null)
        }
        else{
          showToastMsg('Error',res?.message || "Something Went Wrong!")
          eventEmitter('ORP','Prepaid Recharge Fetch Config Failed','click',res?.message,null);
        }
      })
      .catch((error) => {
        hideLoader();
        showToastMsg('Error',error?.error?.message ||error?.message|| "Something Went Wrong!")
        eventEmitter('ORP','Prepaid Recharge Fetch Config Failed','click',error?.error?.message,null);
      });
  };

  const buyAirtime = () => {
    let txnData = {
      msisdn:
        process.env.REACT_APP_OPCO.toLowerCase() == "ga"
          ? "0" + formData.msisdn
          : formData.msisdn,
      enableDualCurrency:
        process.env.REACT_APP_OPCO.toLowerCase() == "cd" ? true : false,
      amount: formData.amount,
      lineType: "PREPAID",
      transactionType: "PREPAID_RECHARGE",
      productCode: null,
      description: "Recharge Amount",
      currency: process.env.REACT_APP_CURRENCY,
      redirectPage: "/prepaid-recharge",
      rechargeType: RECHARGE_TYPE.PREPAID_RECHARGE,
      theme: "theme-v1",
    };
    sessionStorage.setItem("queryData", JSON.stringify(txnData), true);
    navigate("/payment/start", { replaceUrl: true });
    eventEmitter('ORP','Prepaid Recharge Now Clicked','click','Prepaid Recharge Now Clicked',null);
  };

  const handleLineTypeData = (data) => {
    setLineTypeData(data);
    if (data?.result != "PREPAID") {
      setMsidnError('Invalid Mobile No')
    } else {
      setMsidnError('')
    }
  }

  const crossClick = () => {
    setFormData({ ...formData, msisdn: "" });
    setLineType(false);
  };
  const handleDataForm = (data) => {
    setLineType(data);
    setmsisdnLineType("PREPAID");
  };
  const onPackSelect = (event, bundleInfo) => {
    // event.persist();
    if (!bundleInfo) {
      return;
    }

    setFormData({ ...formData, amount: bundleInfo?.amount?.toString() });
    let txnData = {
      msisdn:
        process.env.REACT_APP_OPCO?.toLowerCase() == "ga"
          ? "0" + formData?.msisdn
          : formData?.msisdn,
      enableDualCurrency:
      process.env.REACT_APP_OPCO?.toLowerCase() == "cd" ? true : false,
      amount: bundleInfo?.amount,
      bundleInfo: bundleInfo,
      lineType: bundleInfo?.linetype || "PREPAID",
      productCode: bundleInfo?.productCode,
      description: "Bundle Purchase",
      currency: bundleInfo?.currency || process.env.REACT_APP_CURRENCY,
      redirectPage: "/prepaid-recharge",
      rechargeType: "BUNDLE PURCHASE",
      transactionType:
        (bundleInfo?.lineType || msisdnLineType).toLowerCase() == "prepaid"
          ? "PREPAID_BUNDLES"
          : "POSTPAID_BUNDLES",
    };
    sessionStorage.setItem("queryData", JSON.stringify(txnData), true);

    navigate("/payment/start");
    eventEmitter('ORP','Prepaid Recharge Bundle Selected','click','Prepaid Recharge Bundle Selected',null);

  };
  return (
    <>
      <div className="online-recharge">
        <div className="container">
          <h2>Online Recharge</h2>
        </div>
      </div>
      <div className="bundle-recharge-form">
        <div className="container">
          <div className="form-container">
            <FormContainer
              formInput={formData}
              handleChange={handleChange}
              crossClick={crossClick}
              sendDataToParent={handleDataForm}
              maxAmount={maxAmount}
              minAmount={minAmount}
              minError={minError}
              maxError={maxError}
              sendLineTypeData={handleLineTypeData}
              msidnError={msidnError}
            ></FormContainer>
            <button className="buyAirtimeBtn" disabled={(!formData.msisdn||!formData?.amount || minError || maxError || lineTypeData?.result!="PREPAID")} onClick={buyAirtime}>BUY AIRTIME</button>
           
          </div>
        </div>
      </div>

      
      {(lineTypeRes && lineTypeData?.result=="PREPAID") && (
              <BundleTable
                msisdn={formData?.msisdn}
                linetype={msisdnLineType}
                buyBundleEvent={onPackSelect}
              />
            )}
      {toastMsg && <ToastMsg message={toastMsg.message} type={toastMsg.type} onClose={()=> setToastMsg(null)}></ToastMsg>}
    </>
  );
};

export default Prepaid;
